// Variables
@import "src/styles/variables/bootstrap";
@import "../variables/colors";

.property-search {
  flex: 1 0 auto;
  display:flex;
  flex-direction: row;
  background-color: white;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  touch-action: none;

  &.full-height {
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
    background-color: $color-background;

    .panel-search-results {
      padding-top: 80px;
    }

    .content-results {
      width:100%;
      height:100%;
    }
  }

  .property-search-left-panel{
    min-width: 280px;
    flex: 1 1;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: minmax(0,1fr);
    grid-template-rows: min-content minmax(0,1fr);
  }

  .property-search-main-panel{
    flex: 1 1 auto;
    max-width: 50%;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  .property-search-content-switch {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;

    button {
      display: flex;
      .icon {
        margin-right: 4px;
      }
    }
  }

  .panel-search-parameters {
    .parameters {
      display: flex;
      .search-destination {
        flex: 1 1 200px;
        margin-right: 16px;
      }

      .search-datepicker {
        flex: 1 1 300px;
      }

      .search-guests {
        flex: 0 0 130px;
        margin-left: 16px;
      }
    }

    .filters-detail {
      .filter-actions {
        text-align: right;
        margin-bottom: 16px;
      }
    }
  }

  .property-search-panel-padding {
    padding: 24px 72px 16px 40px;

    @include media-breakpoint-down(md) {
      &.property-search-panel-padding {
        padding: 16px;
      }
    }
  }

  .panel-search-area {
    position: relative;

    .panel-search-loading{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba($color-white, 0.6);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .panel-search-results {
      background-color: $color-background;
      overflow-y: auto;

      .results-controls-panel {
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;

        .results-header {
          display: flex;
          justify-content: space-between;
        }

        .results-control-button {
          flex-shrink: 0;
          display: flex;
          flex-direction: row;
          align-items: center;

          .results-control-button-sorting{
            display: flex;
          }

          .filters-control {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .info-box {
          overflow: hidden;
          background: $color-accordion;
          border-radius: 8px;
          padding: 16px 20px;
          display:flex;

          .info-bulb {
            margin-right: 13px;
          }
        }
      }

      .results-container {
        margin: -8px;
        padding-bottom: 32px;

        .results-item {
          display: flex;
        }
      }

      .scroll-to-top-button-container {
        position: absolute;
        bottom: 20px;
        right: 90px;

        .scroll-to-top-icon {
          transform: translate(-50%, -50%);
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
        }

        .scroll-to-top-button {
          width: 48px;
          height: 48px;
          background: $color-white;
          color: $color-secondary;
          border: 2px solid #337A87;
          box-sizing: border-box;
          box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(20px);
          border-radius: 100px;
          z-index: 10;
          position: relative;

          &:hover {
            border-color: darken($color-secondary, 15%);
            color: darken($color-secondary, 15%);
          }
          &:active {
            border-color: darken($color-secondary, 15%);
            color: $color-white;
            background: darken($color-secondary, 15%);
          }
        }
      }

      @include media-breakpoint-down(md) {
        .scroll-to-top-button-container {
          position: relative;
          bottom: 0;
          right: 0;
          text-align: center;
          z-index: unset;
        }
      }

      .end-of-results {
        align-items: center;
        text-align: center;
        padding-bottom: 70px;

        .end-of-results-image {
          max-width: 90%;
        }

        @include media-breakpoint-down(lg) {
          .end-of-results-image {
            width: 240px;
            height: 138px;
          }
        }
      }

      .results-delimiter {
        border-top:1px solid rgba($color-gray, 0.2);
        padding-bottom: 16px;
        padding-top: 32px;
      }

      .results-delimiter-mobile {
        padding-bottom: 16px;
        text-align: center;
      }

      .load-more-results {
        margin-bottom: 60px;
        //margin-top: 20px;
      }
    }
  }
}

.mobile-search-dialog-title {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;

  .title-navigation {
    cursor: pointer;
    text-align: center;
    flex-basis: 100%;
    display: inline-block;
    padding: 16px 0;

    &.active {
      color: $color-primary;
      border-bottom: 2px solid $color-primary;
    }
  }
}

// Used in both desktop and mobile:
.space-filter-subsection {
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-container .dialog .dialog-content.dialog-scrollable .dialog-content-content {
  overflow-y: auto;
}

/** PropertySearchComponent */
.property-search-result {
  margin: 8px;
  padding: 16px 16px 24px;
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  background-color: white;

  .property-search-result-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .property-search-result-headline {
      flex-grow: 1;
    }

    .property-search-result-image {
      position: relative;
      border-radius: 8px;
      height: 200px;

      a {
        border-radius: inherit;
        object-fit: cover;
      }

      img {
        border-radius: inherit;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .vacay-image-badge {
        position: absolute;
        z-index:10;
        right:0;
        top:0;
        margin: 8px;
      }
    }

    .property-search-result-icons {
      .icon {
        vertical-align: text-top;
        .icon img {
          object-fit: fill;
          margin-top: 2px;
        }
      }
    }
  }
}
